import * as React from 'react';
import { NavLink } from 'react-router-dom';
import NavigationLinks from '../NavigationLinks/NavigationLinks';
import { ReactComponent as Logo } from '../../logo.svg';
import styles from './Navigation.module.css';

type IProps = {
  tripID: string;
  badge: string;
};

const Navigation: React.FC<IProps> = ({ tripID, badge }) => (
  <div className={styles.nav}>
    <div className={styles.nav__branding}>
      <NavLink
        exact
        to={{ pathname: '/', search: `?resa=${tripID}` }}
        aria-label="SpringTime"
      >
        <Logo />
      </NavLink>
    </div>

    <NavigationLinks tripID={tripID} />

    <NavLink
      exact
      to={{ pathname: '/', search: `?resa=${tripID}` }}
      activeClassName={styles.navLink__active}
      className={`${styles.nav__link} ${styles.nav__linkHome}`}
    >
      <svg
        className={styles.nav__icon}
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g fill="none">
          <path d="M0 0h48v48H0z" />
          <path
            fill="currentcolor"
            d="M8.0007 19.134l-1.0291.6094c-.9656.5364-2.1831.1885-2.7196-.777-.5364-.9656-.1885-2.1832.777-2.7197l16.0572-9.4916a5.9998 5.9998 0 0 1 5.8276 0l16.0571 9.4916c.9656.5365 1.3135 1.754.777 2.7196-.5364.9656-1.754 1.3135-2.7195.777l-1.0283-.6089V40.8c0 .6627-.5373 1.2-1.2 1.2H27.2003c-.6627 0-1.2-.5373-1.2-1.2v-6.4a.4.4 0 0 0-.4-.4h-3.1999a.4.4 0 0 0-.4.4v6.4c0 .6627-.5372 1.2-1.2 1.2H9.2006c-.6627 0-1.2-.5373-1.2-1.2V19.134zM20 24h7.9998c1.1046 0 2 .8954 2 2s-.8954 2-2 2H20c-1.1046 0-2-.8954-2-2s.8954-2 2-2zm-7.9994-7.2346V37.6a.4.4 0 0 0 .4.4h5.1999a.4.4 0 0 0 .4-.4V32c0-1.1046.8954-2 2-2h7.9998c1.1045 0 2 .8954 2 2v5.6a.4.4 0 0 0 .4.4h5.1999a.4.4 0 0 0 .4-.4V16.7658l-11.029-6.5308a2 2 0 0 0-1.9425 0l-11.0281 6.5304z"
          />
        </g>
      </svg>
      <span className={styles.nav__text}>Hem</span>
    </NavLink>

    <NavLink
      to={{ pathname: '/program', search: `?resa=${tripID}` }}
      activeClassName={styles.navLink__active}
      className={styles.nav__link}
    >
      <svg
        className={styles.nav__icon}
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g fill="none">
          <path d="M0 0h48v48H0z" />
          <path
            fill="currentcolor"
            d="M14.8 22h26.4a.8.8 0 0 1 .8.8v2.4a.8.8 0 0 1-.8.8H14.8a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 1 .8-.8zm0-12h26.4a.8.8 0 0 1 .8.8v2.4a.8.8 0 0 1-.8.8H14.8a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 1 .8-.8zm0 24h26.4a.8.8 0 0 1 .8.8v2.4a.8.8 0 0 1-.8.8H14.8a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 1 .8-.8zm-8-24h2.4a.8.8 0 0 1 .8.8v2.4a.8.8 0 0 1-.8.8H6.8a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 1 .8-.8zm0 12h2.4a.8.8 0 0 1 .8.8v2.4a.8.8 0 0 1-.8.8H6.8a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 1 .8-.8zm0 12h2.4a.8.8 0 0 1 .8.8v2.4a.8.8 0 0 1-.8.8H6.8a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 1 .8-.8z"
          />
        </g>
      </svg>
      <span className={styles.nav__text}>Program</span>
    </NavLink>

    <NavLink
      to={{ pathname: '/instruktorer', search: `?resa=${tripID}` }}
      activeClassName={styles.navLink__active}
      className={styles.nav__link}
    >
      <svg
        className={styles.nav__icon}
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g fill="none">
          <path d="M0 0h48v48H0z" />
          <path
            fill="currentcolor"
            d="M19.2 38h9.6c.6627 0 1.2.5373 1.2 1.2v1.6c0 .6627-.5373 1.2-1.2 1.2h-9.6c-.6627 0-1.2-.5373-1.2-1.2v-1.6c0-.6627.5373-1.2 1.2-1.2zM32 34c0-3.3137-2.6863-6-6-6h-4c-3.3137 0-6 2.6863-6 6v8c-2.2091 0-4-1.7909-4-4H8c-2.2091 0-4-1.7909-4-4v-4c0-3.8641 2.1917-7.2163 5.3997-8.8814C7.9105 19.513 7 17.3628 7 15c0-4.9706 4.0294-9 9-9 3.0645 0 5.8515 1.5463 7.494 4.014a9.1437 9.1437 0 0 1 1.012 0C26.149 7.5456 28.936 6 32 6c4.9706 0 9 4.0294 9 9 0 2.3628-.9105 4.5129-2.3997 6.1186C41.8083 22.7836 44 26.136 44 30v4c0 2.2091-1.7909 4-4 4h-4c0 2.2091-1.7909 4-4 4v-8zm4 0h4v-4c0-3.3137-2.6863-6-6-6h-2.5155a9.0403 9.0403 0 0 1-.8842 1.1186C33.8083 26.7836 36 30.136 36 34zm1-19c0-2.7614-2.2386-5-5-5a4.9926 4.9926 0 0 0-3.3539 1.2904C31.2555 12.8663 33 15.7294 33 19c0 .3068-.0154.61-.0453.909C35.2595 19.4634 37 17.435 37 15zm-8.1141 5.0671A5.0326 5.0326 0 0 0 29 19c0-2.7614-2.2386-5-5-5s-5 2.2386-5 5 2.2386 5 5 5c2.3952 0 4.397-1.6842 4.8859-3.9329zM12 34c0-3.8641 2.1917-7.2163 5.3997-8.8814A9.0403 9.0403 0 0 1 16.5155 24H14c-3.3137 0-6 2.6863-6 6v4h4zm4-24c-2.7614 0-5 2.2386-5 5 0 2.435 1.7405 4.4634 4.0453 4.909A9.1066 9.1066 0 0 1 15 19c0-3.2706 1.7445-6.1337 4.3539-7.7096A4.9928 4.9928 0 0 0 16 10z"
          />
        </g>
      </svg>
      <span className={styles.nav__text}>Ledare</span>
    </NavLink>

    <div className={styles.nav__footer}>
      {!badge ? null : <img src={badge} alt="" />}

      <span className={styles.copy}>
        &copy; 2019 Springtime Resor AB
      </span>
    </div>
  </div>
);

export default Navigation;
