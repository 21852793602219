import * as React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './Loader.module.css';

const Loader = () => (
  <>
    <div className={styles.loaderHeader} />
    <div className={styles.loaderContent}>
      <ContentLoader
        height={160}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="340" height="8" />
        <rect x="0" y="20" rx="3" ry="3" width="370" height="8" />
        <rect x="0" y="40" rx="3" ry="3" width="350" height="8" />
        <rect x="0" y="60" rx="3" ry="3" width="380" height="8" />
        <rect x="0" y="80" rx="3" ry="3" width="200" height="8" />
      </ContentLoader>
    </div>
  </>
);

export default Loader;
