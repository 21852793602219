import { createStore, compose, applyMiddleware } from 'redux';
// import { offline } from '@redux-offline/redux-offline';
// import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import thunk from 'redux-thunk';
import reducer from '../reducers/reducers';

const store = createStore(
  reducer,
  compose(
    applyMiddleware(thunk),
    // offline(offlineConfig),
  ),
);

export default store;
