import {
  FETCH_TRIP_BEGIN,
  FETCH_TRIP_SUCCESS,
  FETCH_TRIP_FAILURE,
} from './actionTypes';

// Error handling
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchTripBegin = () => ({
  type: FETCH_TRIP_BEGIN,
});

export const fetchTripSuccess = posts => ({
  type: FETCH_TRIP_SUCCESS,
  payload: { posts },
});

export const fetchTripFailure = error => ({
  type: FETCH_TRIP_FAILURE,
  payload: { error },
});

export function tripFetchData(tripID) {
  return (dispatch) => {
    dispatch(fetchTripBegin(true));
    return fetch(`${process.env.REACT_APP_API_URL}${tripID}`)
      .then(handleErrors)
      .then(response => response.json())
      .then(data => dispatch(fetchTripSuccess(data)))
      .catch(error => dispatch(fetchTripFailure(error)));
  };
}
