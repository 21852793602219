import * as React from 'react';
import ContentLoader from 'react-content-loader';
import Header from '../../components/Header/Header';
import { ReactComponent as Logo } from '../../logo.svg';
import styles from './Skeleton.module.css';

const Skeleton = () => (
  <div className={styles.skeleton__wrapper}>
    <div className={styles.skeleton__aside}>
      <Logo />

      <ContentLoader
        height={240}
        width={160}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="10" y="27" rx="0" ry="0" width="140" height="14" />
        <rect x="10" y="58" rx="0" ry="0" width="100" height="14" />
        <rect x="10" y="89" rx="0" ry="0" width="130" height="14" />
        <rect x="10" y="120" rx="0" ry="0" width="110" height="14" />
        <rect x="10" y="151" rx="0" ry="0" width="100" height="14" />
        <rect x="10" y="182" rx="0" ry="0" width="110" height="14" />
      </ContentLoader>
    </div>
    <div className={styles.skeleton__main}>
      <Header />
    </div>
  </div>
);

export default Skeleton;
