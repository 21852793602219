import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { tripFetchData } from './actions/actionCreators';
import Loader from './components/Loader/Loader';
import Skeleton from './components/Loader/Skeleton';
import Navigation from './components/Navigation/Navigation';
import ReactGA from 'react-ga';

const HamburgerMenu = lazy(() => import('./components/HamburgerMenu/HamburgerMenu'));
const PageWrapper = lazy(() => import('./containers/PageWrapper/PageWrapper'));
const Schedule = lazy(() => import('./containers/Schedule/Schedule'));
const Instructors = lazy(() => import('./containers/Instructors/Instructors'));
const InstructorItem = lazy(() => import('./containers/Instructors/InstructorItem'));
const FourOhFour = lazy(() => import('./containers/FourOhFour/FourOhFour'));

class App extends Component {
  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    post: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tripID: '',
    };
  }

  componentDidMount() {
    const {
      post,
      fetchData,
      location: { search },
    } = this.props;

    const values = queryString.parse(search);

    ReactGA.initialize('UA-128153708-3');

    this.setState({
      tripID: values.resa,
    });

    if (!post.length > 0) {
      fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchData,
      location: { pathname },
    } = this.props;

    if (pathname !== prevProps.location.pathname) {
      fetchData();
    }
  }

  renderRoutes() {
    const {
      post: { menu },
    } = this.props;

    return menu
      ? Object.values(menu).map(routeItem => (
        <Route
          key={routeItem.ID}
          path={`/${routeItem.slug}`}
          render={props => <PageWrapper {...props} data={routeItem} />}
        />
      ))
      : null;
  }

  render() {
    const {
      error, // eslint-disable-line react/prop-types
      loading,
      post,
      post: { menu },
    } = this.props;
    const { tripID } = this.state;

    if (error) {
      return <p>Ett fel uppstod när resan laddades!</p>;
    }

    if (loading) {
      return <Skeleton />;
    }

    return (
      <>
        <div className="container">
          <Suspense fallback={false}>
            <HamburgerMenu tripID={tripID} menu={menu} badge />
          </Suspense>

          <Suspense fallback={<Loader />}>
            <Switch>
              {this.renderRoutes()}
              <Route
                exact
                path="/"
                render={props => (
                  <PageWrapper {...props} data={post.frontpage} />
                )}
              />
              <Route
                path="/program"
                render={props => (
                  <Schedule
                    {...props}
                    data={post.program}
                    instructor={post.instructors}
                  />
                )}
              />
              <Route
                exact
                path="/instruktorer"
                render={props => (
                  <Instructors
                    {...props}
                    data={post.instructors}
                    tripID={tripID}
                  />
                )}
              />
              <Route
                path="/instruktorer/:id"
                render={props => (
                  <InstructorItem
                    {...props}
                    data={post.instructors}
                    tripID={tripID}
                  />
                )}
              />
              <Route render={() => <FourOhFour />} />
            </Switch>
          </Suspense>
        </div>

        <Navigation tripID={tripID} badge={post.frontpage && post.frontpage.climate_image} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  post: state.tripPage.items,
  error: state.tripPage.error,
  loading: state.tripPage.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const values = queryString.parse(ownProps.location.search);

  return {
    fetchData: () => {
      dispatch(tripFetchData(values.resa));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
