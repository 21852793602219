import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

type IProps = {
  tripID: string;
  menu: object;
};

const NavigationLinks: React.FC<IProps> = ({ tripID, menu }) => {
  const renderLinks = () => (menu
    ? Object.values(menu).map(menuItem => (
      <NavLink
        className="menulink"
        key={menuItem.ID}
        to={{
          pathname: `/${menuItem.slug}`,
          search: `?resa=${tripID}`,
        }}
      >
        {menuItem.title}
      </NavLink>
    ))
    : null);

  return <>{renderLinks()}</>;
};

const mapStateToProps = (state: any) => ({
  menu: state.tripPage.items.menu,
});

export default connect(mapStateToProps)(NavigationLinks);
