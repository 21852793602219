import {
  FETCH_TRIP_BEGIN,
  FETCH_TRIP_SUCCESS,
  FETCH_TRIP_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  items: {},
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TRIP_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case FETCH_TRIP_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.posts,
      };

    case FETCH_TRIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.posts,
        items: {},
      };

    default:
      return state;
  }
}
